import * as React from "react"
import { navigate } from 'gatsby-link';
import styled from '@emotion/styled'
import Layout from '../components/Layout/Layout';
import { 
  PageTitleHeader, 
  FormInputBlock,
  InputFieldset,
  FormInputText,
  FormTextAreaBlock,
  FormInputRadio,
  FormTextArea,
  FormButton,
  RequiredAsterisk
 } from '../globalStyles';


const Container = styled.div`
  margin: 3rem auto;
  max-width: 960px;
  padding: 0 20px;
`


function encode(data) {
  return Object.keys(data).map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])).join('&');
}

function convertDateFormat(date) {
  const newDate = new Date(date.toString().replace(/-/g, '/'));

  const formattedDate = newDate.toLocaleDateString('en-us', {
    timeZone: 'America/Toronto',
    weekday: 'long',
    year: 'numeric', 
    month: 'long', 
    day: 'numeric'
  });

  return formattedDate;
}

function convertTimeFormat(time) {
  // time is optional so break early if value doesn't exist 
  if (!time) return;

  time = time.split(':'); // convert to array
 
  // fetch
  var hours = Number(time[0]);
  var minutes = Number(time[1]);

  let timeValue;

  if (hours > 0 && hours <= 12) {
    timeValue= "" + hours;
  } else if (hours > 12) {
    timeValue= "" + (hours - 12);
  } else if (hours == 0) {
    timeValue= "12";
  }

  timeValue += (minutes < 10) ? ":0" + minutes : ":" + minutes;  // get minutes
  timeValue += (hours >= 12) ? " P.M." : " A.M.";  // get AM/PM

  return timeValue;
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function convertStateToSingle(stateObject) {
  return Object.keys(stateObject).map((key) => {
    const newKey = capitalizeFirstLetter(key).split('_').join(' ');

    // don't add the key and value if value doesn't exist
    if (!stateObject[key]) return;

    return encodeURIComponent(newKey) + ': ' + encodeURIComponent(stateObject[key]);
  }).join('%0A');
 }

const EidForm = () => {
  let pathname = typeof window !== "undefined" ? window.location.pathname : ""

  const updateSubject = (e) => {
    document.getElementById("email-subject").setAttribute('value', "Eid form form for " + e.target.value);
  }

  const [ state, setState ] = React.useState({});

  const handleChange = (e) => {
    setState({ ...state, [e.target.id]: e.target.value });
    console.log(state);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // convert Dates to more readable format
    Object.assign(state, { date_order_placed: `${convertDateFormat(state['date_order_placed'])} (${state['date_order_placed']})` });

    const form = e.target;

    let stringBody = encode({
      'form-name': form.getAttribute('name'),
      'subject': 'Eid Order form for ' + state['name_of_client']
    });

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: stringBody + '&eid_order_form=' + convertStateToSingle(state)
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error));
  };

  const updateQuantity = (e) => {
    let item = orderItems.find(o => o.id === e.target.id);
    // console.log('item', item);
    // let name = item.name;
    // let totalPrice = item.price * e.target.value;
    // let itemState = {
    //   "name": name,
    //   "qty": e.target.value,
    //   "totalPrice": totalPrice
    // }
    setState({ ...state, [e.target.id]: e.target.value });
    console.log(state);
  }

  const orderItems = [
    {
      "id": 1,
      "type": "Eid Treat Box",
      "name": "Small Treat Box",
      "price": 25
    }, {
      "id": 2,
      "type": "Eid Treat Box",
      "name": "Large Treat Box",
      "price": 40
    }, {
      "id": 3,
      "type": "Eid Brownie Box",
      "name": "Individual S'more Brownie (min 5)",
      "price": 7,
      "min": 5
    }, {
      "id": 4,
      "type": "Eid Brownie Box",
      "name": "Individual Dubai Chocolate Brownie (min 5)",
      "price": 7,
      "min": 5
    }, {
      "id": 5,
      "type": "Eid Brownie Box",
      "name": "Full Tray (12) S'more Brownies",
      "price": 50
    }, {
      "id": 6,
      "type": "Eid Brownie Box",
      "name": "Full Tray (12) Dubai Chocolate Brownies",
      "price": 50
    }, {
      "id": 7,
      "type": "Eid Brownie Box",
      "name": "Mix Brownie Tray",
      "price": 55
    }, {
      "id": 8,
      "type": "Eid Cake",
      "name": "8\" Sheetcake" ,
      "price": 150
    }, {
      "id": 9,
      "type": "Eid Cake",
      "name": "10\" Sheetcake" ,
      "price": 170
    }, {
      "id": 10,
      "type": "Eid Cake",
      "name": "12\" Sheetcake" ,
      "price": 200
    }, 
  ]


  return(
  <Layout
    title="Eid Order Form"
    description="In order to proceed with your order, please complete the form."
    noIndex={true}
    pathname={pathname}
  >
    <Container>
      <PageTitleHeader> Eid Form </PageTitleHeader>

      <p>
        In order to proceed with your order, please complete the form below. 
      </p>

      <form name="Eid Order Form" encType="multipart/form-data" action="/eid-form/thanks" method="POST" data-netlify="true" onSubmit={handleSubmit}>
      
        <input type="hidden" name="form-name" value="Eid Order Form" />
        {/* <input type="hidden" name="subject" value="Eid Order Form" id="email-subject"/> */}


        <FormInputBlock>
          <InputFieldset >
            <legend> Name of Client<RequiredAsterisk>*</RequiredAsterisk> </legend>
            <FormInputText type="text" id="name_of_client" required onChange={handleChange}/>
          </InputFieldset>
        </FormInputBlock>

        <FormInputBlock>
          <InputFieldset>
            <legend> Phone Number<RequiredAsterisk>*</RequiredAsterisk> </legend>
            <FormInputText type="phone" id="phone" required onChange={handleChange}/>
          </InputFieldset>
        </FormInputBlock>

        <FormInputBlock>
          <InputFieldset >
            <legend> Email<RequiredAsterisk>*</RequiredAsterisk> </legend>
            <FormInputText type="email" id="email" required onChange={handleChange}/>
          </InputFieldset>
        </FormInputBlock>

        <FormInputBlock>
         <InputFieldset >
         <legend> Emergency Contact<RequiredAsterisk>*</RequiredAsterisk> </legend>
          <FormInputText type="text" id="emergency_contact" required onChange={handleChange}/>
          </InputFieldset>
        </FormInputBlock>

        <FormInputBlock>
          <InputFieldset >
          <legend> Emergency Contact Number<RequiredAsterisk>*</RequiredAsterisk> </legend>
          <FormInputText type="phone" id="emergency_contact_number" required onChange={handleChange}/>
          </InputFieldset>
        </FormInputBlock>

        <FormInputBlock>
          <InputFieldset>
            <legend>Date Order Placed<RequiredAsterisk>*</RequiredAsterisk> </legend>
            <FormInputText type="date" id="date_order_placed" placeholder="Date Order Placed*" required onChange={handleChange}/>
          </InputFieldset>
        </FormInputBlock>

        <FormInputBlock>
          <InputFieldset>
            <legend>Pickup location<RequiredAsterisk>*</RequiredAsterisk></legend>
              <label>
                <FormInputRadio type="radio" name="eid_order_form" id="pickup_location" value="Donlands" required onChange={handleChange}/> Donlands (Danforth and Donlands)
              </label>
              <br></br>
              <label>
                <FormInputRadio type="radio" name="eid_order_form" id="pickup_location" value="Scarborough" required onChange={handleChange}/> Scarborough (Markham and Sheppard)
              </label>
          </InputFieldset>
        </FormInputBlock>

        <FormInputBlock>
          <InputFieldset >
            <legend>If you'd like to order a treat box, please specify your cake flavours</legend>
            <FormTextAreaBlock>
              <FormTextArea id="cake_flavours" rows="4" cols="50" placeholder="Notes" onChange={handleChange}/>
            </FormTextAreaBlock>
          </InputFieldset>
        </FormInputBlock>

        <table className="eid-table">
          <tr>
            <th>Name</th>
            <th>Price</th>
            <th>Qty</th>
            {/* <th>Total Price</th> */}
          </tr>
          { orderItems.map((item) => {
                return <tr>
                  <td> {item.name} </td>
                  <td> ${item.price} </td>
                  <td> <input type="number" style={{width: "25px"}} id={item.name} min="0" placeholder="0" onChange={updateQuantity}></input> </td>
                  {/* <td> </td> */}
                </tr>
                })
              }
              {/* <tr>
                <th colspan="2"> TOTAL PRICE:</th>
                <th colspan="2"> $500</th>
              </tr> */}
        </table>
        

{/*         
        <FormInputBlock>
         <InputFieldset >
         <legend> Password and name for e-transfer<RequiredAsterisk>*</RequiredAsterisk> </legend>
          <FormInputText type="text" id="emergency_contact" required onChange={handleChange}/>
          </InputFieldset>
        </FormInputBlock> */}





        <FormButton type="submit"> Submit </FormButton>

      </form>

    </Container>
  
  </Layout>
  )
}

export default EidForm

